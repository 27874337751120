@import ../../../assets/styles/_variables
div.wrapper
    color: $textPrimary
    font-size: 1.6rem
    line-height: 20px
    white-space: pre-wrap
    overflow-wrap: anywhere

    .table__wrapper
        overflow: auto
        margin: 12px 0
        &::-webkit-scrollbar 
            height: 5px

    em
        font-style: italic
    strong
        font-weight: 600

    &.blockDesc
        font-size: 1.6rem
        line-height: 20px
        // color: $secondaryDarkGray
        color: green
    .editor-listitem__ol1
        background: beige
    &>ul,&>ol
        margin-top: 12px
        margin-bottom: 12px
    ol
        margin-left: 24px
        li
            padding-left: 0px
            position: relative
    ul
        list-style: none
        margin-left: 10px
        li
            list-style-type: none
            padding-left: 14px
            position: relative
            &::before
                content: "•"
                display: inline-block
                align-items: center
                white-space: nowrap
                width: 0.5em
                position: absolute
                left: 0
        li:has(> ul)
            &::before
                content: ""
                margin-left: 0
                margin-right: 0
                width: 0
                height: 0
                display: none

    a,a.editor-link
        color: $textBrand
    p,li,p.editor-paragraph,li.editor-listitem
        font-size: 1.6rem
        line-height: 20px
        color: $textPrimary
    p
        margin: 12px 0
        &:has(br)
            margin: 0
    h1,h1.editor-heading-h1
        font-size: 4.8rem
        line-height: 62px
        color: $textPrimary
    h2,h2.editor-heading-h2
        font-size: 4rem
        line-height: 52px
        color: $textPrimary
    h3,h3.editor-heading-h3
        font-size: 3.2rem
        line-height: 42px
        color: $textPrimary
    h4,h4.editor-heading-h4
        font-size: 2.8rem
        line-height: 36px
        color: $textPrimary
    h5,h5.editor-heading-h5
        font-size: 2.4rem
        line-height: 31px
        color: $textPrimary
    h6,h6.editor-heading-h6
        font-size: 2rem
        line-height: 26px
        color: $textPrimary
    h1,h2,h3,h4,h5,h6
        margin: 12px 0
    img
        // object-fit: contain
        width: 100%
        max-width: 100%

@media (max-width: 768px)
    .wrapper
        font-size: 1.4rem

    .wrapper.blockDesc
        font-size: 1.4rem
        text-align: left
.editor-listitem__ol3
    background: red
    font-size: 5rem
