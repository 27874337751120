@import ../../../assets/styles/_styles
@import ../../../assets/styles/_styles
.mobileHeader

	&__buttons
		display: flex
		width: 40px
		justify-content: flex-end
	&__text
		flex: 1
		width: 40%
		text-align: center
		overflow: hidden
		text-overflow: ellipsis
		display: -webkit-box
		-webkit-line-clamp: 2
		line-clamp: 2
		-webkit-box-orient: vertical
	&__menu
		width: 40px
	&__arrow
		width: 40px

		svg
			path
				fill: $iconSecondary
	&__breadcrumbs
		display: none
	&__notifications
		margin-right: 18px

	&__title
		display: flex
		justify-content: center
		padding: 16px
		background: $surfacePrimary
		border-bottom: 1px solid $borderBrandSecondary
		// margin: 0px -16px
		align-items: center

		& > svg
			position: absolute
			left: 24px

		h3
			font-size: 1.6rem
			color: $textPrimary
			line-height: 20px
	&__unread 
		color: $textBadges
		background: $surfaceWarning
		position: absolute
		top: -9px
		left: 10px
		font-size: 1.2rem
		border-radius: 12px
		border: 2px solid $borderPrimary 
		padding: 4px
		height: 20px
		min-width: 20px
		display: flex
		align-items: center
		justify-content: center

	&__menu 
		position: relative
		display: flex
		align-items: flex-end

.menuLeft
	display: flex
	align-items: center

	svg
		width: 8px
		height: 12px
		margin: 0px 13px

		path
			fill: $iconSecondary

	& > span
		font-size: 1.2rem
		color: $textPrimary

.modal
	&__cancel
		width: 20px
		height: 20px

		svg
			path
				fill: $iconPrimary

	&__header
		display: flex
		justify-content: space-between
		padding: 16px
		font-size: 1.6rem
		color: $textSecondary
		border-bottom: 1px solid $borderBrandSecondary
		margin-bottom: 28px

.headerMobile__item
	display: flex
	align-items: center

	svg
		path
			fill: $iconSecondary

.modal__breadcrumbs > div
	display: block

.mobileHeader__notificationButtonMobile, 	.mobileHeader__notificationButtonTablet
	display: flex
	align-items: center


.user__popower_cancel, .user__popower_negate
	display: none

.user__popower
	left: 0
	padding-left: 16px
	background: $surfacePrimary
	width: calc(100vw + 16px)
	height: 100vh
	top: 0px
	text-align: center
	border-radius: 0px
	display: flex
	flex-direction: column
	overflow: scroll
	padding-bottom: 20px
	position: fixed
	z-index: 6

	&_header
		padding-bottom: 20px
		border-bottom: 1px solid $borderBrandSecondary
		margin-bottom: 10px
		margin: 0px 24px 10px
	&_cancel
		display: block
		border-bottom: 1px solid $borderBrandSecondary
		padding: 20px 16px
		text-align: right
		color: $textPrimary

 
		display: flex
		align-items: center
		gap: calc(50% - 24px)
		font-size: 1.6rem
		margin: 0px 0px 0px -16px
 

		svg
			width: 14px
			height: 14px

			path
				fill: $iconPrimary

	&_negate
		display: flex
		margin-top: auto
		justify-content: center
		border-top: 1px solid $borderBrandSecondary
		padding: 20px
		align-items: center
		padding-bottom: 0px
		margin: auto 24px 0px

		svg
			width: 20px
			height: 20px

		div
			margin-left: 24px
			font-size: 1.6rem
			color: $textSecondary
 
@media (min-width: 1181px)
	.mobileHeader__title
		display: none
	.mobileHeader__menu
		display: none
	.mobileHeader__notificationButtonMobile
		display: none

	.mobileHeader
		&__tabletBreadcrumbs
			display: none

@media (min-width: 769px) and (max-width: 1180px)
	.menuLeft
		display: block

	.mobileHeader__menu
		display: block

	.mobileHeader
		&__title
			display: flex
			margin: 0px
			justify-content: space-around
			margin: 0px -48px
			padding: 16px 48px
		// &__menu
		// 	display: none
		&__tabletBreadcrumbs > div
			display: block
			margin: 0px -48px
			border-bottom: 1px solid $borderBrandSecondary
	// .mobileHeader__notificationButtonMobile
	// 	display: none
		&__notificationButtonTablet
			display: flex
			margin-right: 20px
			svg
				path
					fill: $iconSecondary

@media (max-width: 768px)
	.modal
		border-radius: 24px 24px 0px 0px
		height: auto !important
		margin-top: auto
	.mobileHeader
		&__title
			display: flex
			justify-content: space-between
			position: fixed
			width: 100%
			top: 0
			right: 0
			left: 0
			z-index: 99
		&__breadcrumbs
			display: block
			padding-bottom: 16px
			padding-top: 16px
		&__menu
			display: flex
			svg
				path 
					fill: $iconPrimary
		&__notificationButtonMobile
			display: flex
		&__notificationButtonTablet
			display: none

@media (max-width: 576px)
	.mobileHeader
		&__buttons
			width: auto
