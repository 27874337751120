@import '../assets/styles/_variables.sass'

.notifications
	&__title
		font-size: 3.2rem
		margin-top: 24px
		color: $textPrimary
		
	&__preloader
		text-align: center 
		width: 100%
		margin-top: -20px
		& > img
			width: 100px
			height: 100px
			margin: auto

.overlay
	width: 100%
	height: 100%
	top: 0
	left: 0
	background: transparent
	z-index: 3
	position: fixed

.notificationsButton
	position: relative

	button
		background: transparent
		border: none
		padding: 0
		cursor: pointer

	&__unread
		color: $textBadges 
		background: $surfaceWarning
		position: absolute
		top: -9px
		left: 10px
		font-size: 1.2rem
		border-radius: 12px
		border: 2px solid $borderPrimary 
		padding: 4px
		height: 20px
		min-width: 20px
		display: flex
		align-items: center
		justify-content: center

.notificationsPopup
	position: absolute
	width: 400px
	padding: 24px 0px
	background: $surfacePrimary
	box-shadow: $elevation4
	border-radius: 24px
	z-index: 4
	right: -220px
	top: 30px
	transform: translateX(-50%)
	&__header
		display: none

	&__link
		padding: 0px 24px
		margin-bottom: 8px
		text-align: right
		border-bottom: 1px solid $borderBrandSecondary
		padding-bottom: 24px
		& >	a
				font-size: 1.6rem
				color: $textBrand 

	&__noItems
		font-size: 16px
		line-height: 20px
		color: $textTertiary
		text-align: center

.notifications__list_items
	border-radius: 0px 24px 24px 24px
	box-shadow: $elevation2
.notification 
	text-decoration: none
	background: $surfacePrimary
	padding: 24px
	font-size: 20px
	line-height: 24px
	position: relative
	display: flex
	justify-content: space-between
	cursor: pointer
	&:not(:last-child)
		border-bottom: 1px solid $borderBrandSecondary
	&:hover
		background: $buttonNeutralTertiaryHovered
	&__content
		display: flex
		align-items: center
		gap: 18px
 
	&:first-child
		border-radius: 0px 24px 0px 0px
	&:last-child
		margin-bottom: 40px
		border-radius: 0px 0px 24px 24px
		&.notification:first-child
			border-radius: 0px 24px 24px 24px
		&:after
			display: none
	&__date
		padding: 12px 24px 
		font-size: 16px
		line-height: 20px
		color: $textBrand 
		background: $surfacePrimary
		display: inline-block 
		position: relative
		border-radius: 24px 24px 0px 0px
		box-shadow: $elevation2 
		&::after
			content: ""
			display: block
			height: 2px
			width: calc(100%)
			background: $surfacePrimary
			position: absolute
			bottom: 0px
			left: 0px
			z-index: 2
	&__noLink
		cursor: unset 
		&:hover
			background: $surfacePrimary

		& > .notification__more
			cursor: pointer

	&__top
		border-bottom: 1px solid $borderBrandSecondary
		padding-bottom: 18px
		margin-bottom: 16px
		display: flex
		justify-content: space-between

	&__bottom
		display: flex
		align-items: center

	&__link
		flex-shrink: 0
		a, span
			color: $textBrand
			text-decoration: none
			font-weight: 400
			cursor: pointer
			color: $textPrimary	

	&__type
		display: flex
		align-items: center
		text-transform: capitalize
		color: $textSecondary

		svg
			margin-right: 8px

	&__time
		display: flex
		align-items: center
		color: $textTertiary
		font-size: 1.2rem

		svg
			margin-right: 10px

	&__icon
		height: 24px
	
		&_green
			svg
				path 
					fill: $iconPrimary

		&_red
			svg
				path 
					fill: $iconPrimary
	&__text

		font-size: 1.4rem
		word-break: break-word
		overflow-wrap: break-word
		color: $textPrimary
	&__title
		font-size: 1.6rem	
		color: $textPrimary
		word-break: break-word
		overflow-wrap: break-word
	&__more
		position: relative
	&__submenu
		z-index: 2
		white-space: nowrap
		right: 12px
		position: absolute
		padding: 8px 0px
		border-radius: 16px
		background: $surfacePrimary
		box-shadow: $elevation2
		font-size: 1.6rem
		&_item
			padding: 8px 32px
			&:hover
				background: $buttonNeutralTertiaryHovered
		& > a
			color: $textPrimary		
	&__button
		cursor: pointer
		padding: 8px 8px 5px
		border-radius: 10px
		&:hover
			background: $buttonNeutralTertiaryPressed
		& svg
			path
				fill: $iconPrimary
.notificationPopup
	padding: 12px 24px
	font-size: 16px
	line-height: 20px
	display: flex
	align-items: center
	color: $textPrimary

	&:hover
		background: $buttonNeutralTertiaryHovered

	svg	
		width: 20px
		height: 20px


	&__icon
		margin-right: 18px

		&_green
			svg
				path 
					fill: $iconPrimary

		&_red
			svg
				path 
					fill: $iconPrimary

	&__body
		margin-left: 12px
 

	&__bottom
		display: flex 
		align-items: center

	&__viewed
		height: 8px
		width: 8px
		background: $surfaceBrand
		border-radius: 10px
		margin-right: 8px

	&__time
		color: $textTertiary
		font-size: 1.2rem

	&__text 
		display: block
		word-break: break-word
		overflow-wrap: break-word
		a 
			text-decoration: none
			color: unset
		span
			cursor: pointer
		 
.noItems
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	color: $textSecondary

	h3
		margin: 40px 0px 16px 0px
		font-weight: 700
		font-size: 32px
		line-height: 40px

	p
		font-size: 20px
		line-height: 24px

@media (min-width: 769px) and (max-width: 1180px)
	.notifications
		padding-bottom: 24px
		margin-bottom: -24px
		&__title
				display: none
	.notificationsPopup > div:first-child
		display: none


@media (max-width: 768px)
	.notifications
		&__title
				display: none
	.notificationsPopup
		top: 0px
		bottom: unset
		left: unset
		right: 0px
		transform: none
		height: calc(100vh)
		background: $surfaceSecondary
		padding: 0px 24px
		display: flex
		flex-direction: column
		padding-bottom: 24px
		position: relative
		width: 100vw
		margin: 0px -24px
		z-index: 6 
		padding-right: 16px
		height: auto
		margin-top: auto
		bottom: 0
		top: unset
		position: relative
		left: 24px
		padding-left: 16px
		padding-bottom: 0px

		&__header
			display: flex
			justify-content: space-between
			margin-bottom: 16px
			padding: 16px
			font-size: 1.6rem
			border-bottom: 1px solid $borderBrandSecondary

		& > div:first-child
				margin-bottom: 20px

		&__link
			border-bottom: 1px solid $borderBrandSecondary
			padding-bottom: 12px
			margin-bottom: 12px

	.notificationPopup
		margin-bottom: 0px

 
		&:not(:last-child)
			border-bottom: 1px solid $borderBrandSecondary
			margin-bottom: 12px

		&__body
			margin-left: 12px
			line-height: 14px
			font-size: 1.2rem

		&__bottom
			margin-top: 4px	
			margin-bottom: 12px

		&__time
			margin-bottom: 0px
			
 
	.overlay
		display: none


	.notification
		padding: 12px 20px 12px

		&__content
			gap: 12px

		&__top
			font-size: 1.6rem
			margin-bottom: 12px
			padding-bottom: 12px

			svg
				width: 20px
				height: 20px

		&__bottom
			align-items: center
			justify-content: flex-start
			line-height: 16px

		&__text
			margin-bottom: 4px
			font-size: 1.4rem

		&__link
			text-align: left
			margin-bottom: 12px
			font-size: 1.6rem

		&__type > div
			display: flex
			align-items: center

		&__date
			font-size: 1.4rem