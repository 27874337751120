@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin
.shopItem
    display: block

    &__body
        display: grid
        column-gap: 24px
        grid-template-columns: 2fr 1fr

    &__left
        display: flex
        flex-direction: column
        row-gap: 24px

        & > div
            display: flex
            flex-direction: column
            row-gap: 24px
    &__right > div
        display: flex
        flex-direction: column
        row-gap: 16px
    &__text
        display: flex
        flex-direction: column
        row-gap: 16px
    &__modules
        border-top: 1px solid $borderBrandSecondary
        border-bottom: 1px solid $borderBrandSecondary
        padding: 24px 0px
        display: flex
        flex-direction: column
        row-gap: 24px
    &__desc
        display: flex
        column-gap: 20px
    &__data
        display: flex
        justify-content: space-between

        & > div
            display: flex
            flex-direction: column
            row-gap: 16px
.header
    display: flex
    justify-content: space-between
    padding: 20px 0px 20px 0px
    align-items: center
    &__wrapper
        background: $surfacePrimary
        height: 80px
        border-bottom: 1px solid $borderBrandSecondary
        +r(1180)
            display: none
        &_auth
            background: $surfacePrimary
            height: 80px
            border-bottom: 1px solid $borderBrandSecondary
 
    &__right
        display: flex
        align-items: center
.header__lines
    display: flex
    gap: 48px
    align-items: center
.sidebar
    display: flex
    flex-direction: column
    row-gap: 32px
    align-items: center
    padding-top: 24px

.projects
    margin-top: 24px
    grid-column-gap: 24px
    grid-row-gap: 24px
    -webkit-column-gap: 24px
    column-gap: 24px
    display: grid
    grid-template-columns: repeat(auto-fill,minmax(250px,1fr))
    row-gap: 24px
.projectsCard
    display: flex
    flex-direction: column

    &__inner
        margin-top: 12px
        display: flex
        flex-direction: column
        justify-content: space-between
        flex-grow: 1
        row-gap: 12px

    &__right
        display: flex
        flex-direction: column

    &__left
        display: flex
        flex-direction: column

.products
    margin-top: 24px
    grid-column-gap: 24px
    grid-row-gap: 24px
    -webkit-column-gap: 24px
    column-gap: 24px
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    row-gap: 24px
    +r(1180)
        grid-template-columns: 1fr 1fr 1fr
    +r(768)
        grid-template-columns: 1fr
    &__panel
        margin-top: 24px
        display: flex
        flex-direction: column
        gap: 24px 
        +r(1180)
            margin-top: 81px
            padding-top: 24px

            & > div:first-child
                display: none
        +r(768)
            margin-top: 16px
            padding-top: 0px

    &__buttons
        display: flex
        gap: 8px 
.products__header
    margin-bottom: 24px
    display: flex
    justify-content: space-between

    &_left
        display: flex
        column-gap: 8px

        & > div:not(:first-child)
            background: $surfacePrimary
.productsCard
    display: flex
    flex-direction: column

    &__inner
        margin-top: 12px
        display: flex
        justify-content: space-between
        flex-grow: 1
        row-gap: 12px

    &__right
        display: flex
        flex-direction: column
        row-gap: 12px

    &__left
        display: flex
        flex-direction: column
        row-gap: 12px
        width: 100%

.people__list > div
    display: flex
    row-gap: 32px
    flex-direction: column
    padding: 24px
.people
    &__tabs
        display: flex
        justify-content: space-between
        margin-bottom: 24px

        &_left
            display: flex
            column-gap: 16px
        &_right
            display: flex
            column-gap: 16px
    &__filter
        display: grid
        column-gap: 24px
        grid-template-columns: 1fr 3fr 1fr
        margin-bottom: 24px

.dashboard
    display: grid
    grid-template-columns: 1fr 2fr 
    gap: 24px
    margin-top: 24px
    +r(1180)
        padding-top: 80px
        grid-template-columns: 1fr
    +r(768)
        grid-template-columns: 1fr
    &__titles
        display: flex
        justify-content: space-between
        margin-bottom: 16px
        +r(1180)
            display: none
    &__right
        +r(768)
            display: none
    &__surveys
        &_header
            display: flex
            justify-content: space-between
        & > div
            display: flex
            flex-direction: column
            gap: 24px
    &__survey   
        display: flex
        justify-content: space-between
        & > div
            display: flex
            flex-direction: column
            gap: 8px
        &_lines
            display: flex
            gap: 24px

    &__tablet
        display: none
        +r(1180)
            display: flex
            flex-direction: column 
        +r(768)
            display: none
        & > div 
            flex: 1
        &_lines
            +r(1180)
                display: flex
                flex-direction: column
                gap: 12px
        &_rect
            & > div
                +r(1180)
                    display: flex
                    flex-direction: column
                    gap: 24px   
                    padding-top: 0px !important
        &_margin
            margin: 32px 0px
    &__calendar
        +r(1180)
            display: grid
            grid-template-columns: 1fr 1fr 
            gap: 24px
        +r(768)
            display: block
        &_header
            display: grid
            grid-template-columns: 1fr 5fr 1fr 
            margin-bottom: 24px
            & > div:nth-child(2)
                margin: auto
        &_line
            display: grid
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 
            &:not(:last-child)
                margin-bottom: 24px
    &__content
        display: grid
        grid-template-columns: 1fr 2fr 
        align-items: center
        column-gap: 20px
        & > div > div:not(:last-child)
            margin-bottom: 4px
    &__list
        & > div
            margin-bottom: 14px
    &__bottom  
        +r(1180)
            display: none
    &__cards
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 24px
        margin-bottom: 24px
        +r(1180)
            grid-template-columns: 1fr 1fr 1fr 1fr
    &__card
        &_lines
            display: flex
            flex-direction: column
            gap: 8px
    &__header
        display: flex
        align-items: center
        column-gap: 8px
        margin-bottom: 24px
    &__panel 
        column-gap: 24px
        margin-bottom: 24px
    &__text
        & > div
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            padding: 50px
            & > div
                margin-bottom: 12px
    &__onboarding
        background: $skeletonColor
        border-radius: 24px
        padding: 24px
        overflow: hidden
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-bottom: 24px

        &_header
            display: flex 
            gap: 24px
            & > div:first-child
                background: $surfacePrimary
        &_lines
            width: calc(100% - 80px)
            & > div
                margin-bottom: 16px
                background: $surfacePrimary
        &_body
            display: flex
            column-gap: 24px
            padding-left: 16px
            align-items: center
            & > div
                background: $surfacePrimary
        &_footer
            display: flex
            align-items: center
            justify-content: center
            & > div
                background: $surfacePrimary
        &::after
            display: none
            position: absolute
            top: 0
            right: 0
            bottom: 0
            left: 0
            transform: translateX(-100%)
            background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0))
            animation: shimmer 2s infinite
            content: ''
 

.list__header
    margin-bottom: 24px
    display: flex
    justify-content: flex-end
    column-gap: 16px
.list
    display: block

    &__tabs
        margin-bottom: 24px
        display: flex
        justify-content: space-between
        column-gap: 16px
    &__buttons
        display: flex
        column-gap: 16px
.reports
    &__header
        display: flex
        column-gap: 24px
        justify-content: flex-end
        margin-bottom: 24px
    &__cards
        display: flex
        column-gap: 24px
        margin-bottom: 24px
    &__title
        margin-top: 12px
        display: flex
        margin-bottom: 16px
        column-gap: 4px
        align-items: center
    &__icon
        border-radius: 8px

.doc
    padding-top: 54px
    +r(1180)
        padding-top: 136px
    +r(768)
        padding-top: 54px
    &__left
        display: flex
        flex-direction: column
        gap: 24px
    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 24px
        flex-direction: column
        gap: 8px
    &__buttons
        display: flex
        column-gap: 16px
    &__body
        display: grid
        grid-template-columns: 1fr 2fr
        column-gap: 24px
        +r(1180)
            grid-template-columns: 1fr
            gap: 24px
    &__part
        display: flex
        flex-direction: column
        row-gap: 16px

        &:first-child
            margin-bottom: 24px
    &__card:first-child
        margin-bottom: 24px
    &__title
        display: flex
        column-gap: 8px
        padding-bottom: 16px
        border-bottom: 1px solid $borderBrandSecondary
        align-items: center
        margin-bottom: 24px
        justify-content: space-between
        +r(1180)  
            border: none
            margin-bottom: 0px
            padding-bottom: 0px
        & > div:first-child
            display: flex
            gap: 8px
            align-items: center

    &__data
        display: flex
        justify-content: space-between
        +r(1180)
            display: none

        & > div
            display: flex
            flex-direction: column
            row-gap: 16px
            width: 100%
    &__item
        display: flex
        flex-direction: column
        row-gap: 12px
        & > div
            display: flex
            align-items: center
            column-gap: 12px
        &:not(last-child)
            margin-bottom: 24px

.user
    margin-top: 24px
    +r(768)
        margin-top: -64px
    &__buttons
        +r(1180)
            display: none
    &__header
        display: flex
        column-gap: 8px
        align-items: center
        margin-bottom: 20px
    &__data
        display: flex
        flex-direction: column
        row-gap: 16px

        &:not(:last-child)
            margin-top: 24px
            margin-bottom: 24px
    &__items
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        column-gap: 24px
        row-gap: 24px
        +r(1180)
            grid-template-columns: 1fr 1fr
        +r(768)
            grid-template-columns: 1fr
        & > div
    &__tablet
        display: none
        +r(1180)
            display: block
        +r(768)
            display: none
    &__desktop
        +r(768)
            display: none
.user > .list__tabs
    justify-content: space-between
    +r(1180)
        justify-content: flex-end

.feedback
    &__item
        display: grid
        grid-template-columns: 2fr 1fr
        column-gap: 24px
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 16px
    &__left
        display: flex
        column-gap: 16px
    &__header
        display: flex
        justify-content: space-between
    &__title
        display: flex
        column-gap: 16px
    &__body
        width: 100%

        & > div
            margin-bottom: 16px
            +r(768)
                margin-bottom: 8px

    &__link
        display: flex
        column-gap: 4px
        align-items: center

    &__right
        & > div:first-child
            margin-bottom: 16px

.survey
    &__body
        display: grid
        grid-template-columns: 3fr 1fr
        column-gap: 24px
    &__item
        margin-bottom: 24px
        & > div > div:not(:last-child)
            margin-bottom: 20px
    &__title
        display: flex
        column-gap: 16px
    &__right > div > div:not(:last-child)
        margin-bottom: 24px

.lesson
    &__body
        margin-top: 24px
        display: grid
        grid-template-columns: 3fr 1fr
        column-gap: 24px
    &__left
        & > div > div:not(:last-child)
            margin-bottom: 24px
    &__right
        & > div > div:not(:last-child)
            margin-bottom: 24px

.notifs
    margin-top: 24px
    display: flex
    flex-direction: column
    gap: 24px
    +r(768)
        margin-top: -64px
    & > div:first-child
        +r(1180)
            display: none
    &__caption
        margin-bottom: 24px
        padding-left: 12px
        position: relative
        &::after
            content: ""
            height: 16px
            width: 4px
            background: $surfaceTertiary
            display: block
            position: absolute
            top: 32px
            left: 34px
            border-radius: 2px
    &__list
        display: flex
        flex-direction: column
        gap: 32px

        & > div 
            position: relative
            &::before
                content: ""
                height: 16px
                width: 4px
                background: $surfaceTertiary
                display: block
                position: absolute
                top: -24px
                left: 34px
                border-radius: 2px
    &__buttons
        display: flex
        gap: 8px 

.notif 
    display: flex
    flex-direction: column
    gap: 16px
    position: relative

    &__data
        display: flex
        justify-content: space-between
        &:last-child
            +r(1180)

            +r(768)
                flex-direction: column
                gap: 12px
    &__left
        display: flex
        gap: 8px
        align-items: center
    &__right
        display: flex
        gap: 8px
        align-items: center
    &__user
        display: flex
        align-items: center
        column-gap: 8px
    &__title
        display: flex
        align-items: center
        column-gap: 8px

.notif 

    &__title
        display: flex
        align-items: center
        column-gap: 8px
        margin-bottom: 24px
        padding-bottom: 20px
        border-bottom: 1px solid $borderBrandSecondary
    &__block
        &:not(:last-child)
            margin-bottom: 24px
        & > div:not(:last-child)
            margin-bottom: 12px
    &__user
        margin-bottom: 20px
    &__bottom
        display: flex
        justify-content: space-between

        & > div > div:not(:last-child)
            margin-bottom: 24px

.product
    padding-top: 54px
    +r(1180)
        padding-top: 136px
    +r(768)
        padding-top: 42px
    &__buttons
        display: none
        justify-content: space-between
        margin-bottom: 16px
        +r(1180)
            display: flex 
    &__title
        display: flex
        flex-direction: column
        gap: 4px
    &__lines
        display: flex
        gap: 8px
        margin-top: 8px
        margin-bottom: 16px
    &__body
        display: grid
        grid-template-columns: 4fr 2fr
        column-gap: 24px
        +r(1180)
            grid-template-columns: 1fr      
    &__title
        display: flex
        column-gap: 8px
        align-items: start
    &__right
        +r(1180)
            display: none
    &__right_lines
        display: flex
        flex-direction: column
        gap: 4px
    &__right > div > div:not(:last-child)
        margin-bottom: 16px
    &__left
        display: flex
        flex-direction: column
        gap: 24px
    &__left > div > div:not(:last-child)
        margin-bottom: 16px
    &__line
        display: flex
        column-gap: 8px
        align-items: center
        &:not(:last-child)
            margin-bottom: 24px
    &__block
        display: flex
        justify-content: space-between
        align-items: center

        & > div
            display: flex
            column-gap: 12px
            align-items: center
    &__lines
        & > div
            margin-bottom: 8px

.speakers
    &__body
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 24px
    &__title
        display: flex
        column-gap: 8px
        align-items: center
    &__right > div > div:not(:last-child)
        margin-bottom: 24px
    &__left > div > div:not(:last-child)
        margin-bottom: 24px
    &__item
        border: 1px solid $borderBrandSecondary
        border-radius: 24px
        padding: 24px
        display: flex
        justify-content: space-between

        & > div
            display: flex
            column-gap: 16px
            align-items: center
    &__lines
        & > div
            margin-bottom: 8px
    &__content
        flex-direction: column
        align-items: flex-end !important
        & > div
            margin-bottom: 8px

.reviews
    padding-top: 54px
    +r(1180)
        padding-top: 136px
    +r(768)
        padding-top: 54px
    &__blocks
        display: flex
        flex-direction: column
        gap: 16px
        width: 100%
    &__body
        display: grid
        grid-template-columns: 2fr 1fr
        column-gap: 24px
        +r(1180)
            grid-template-columns: 1fr  
            gap: 16px 
    &__title
        display: flex
        column-gap: 8px
        align-items: center
        +r(768)
            flex-direction: column
            gap: 4px
    &__right
        display: flex
        flex-direction: column
        row-gap: 24px
        & > div > div:not(:last-child)
            margin-bottom: 16px
        +r(1180)
            order: 1
    &__left
        +r(1180) 
            order: 2
    &__line
        display: flex
        column-gap: 8px
        align-items: center

        &:not(:last-child)
            margin-bottom: 24px
    &__item
        display: grid
        grid-template-columns: 1fr
        column-gap: 24px
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 16px
    &__ratingTitle
        display: flex
        justify-content: space-between
    &__rating
        +r(1180)
            display: none
        +r(768)
            display: block

    &__circles
        display: flex
        column-gap: 16px
        align-items: center
@keyframes shimmer
    100%
        transform: translateX(100%)

.login
    display: flex
    min-height: calc(100vh - 81px)

    &__title
        display: flex
        gap: 8px
        align-items: center
        width: 100%
    &__buttons
        display: flex
        gap: 12px
        margin-top: 40px
        justify-content: center
    &__lines
        display: flex
        flex-direction: column
        gap: 8px
        align-items: center
        margin-top: 47px 

    &__left
        width: 50%
        border-right: 1px solid $borderBrandSecondary
        background: $surfacePrimary
        display: flex
        justify-content: center
        align-items: center
        +r(1180)
            display: none

    &__right
        width: 50% 
        background: $surfaceSecondary
        display: flex
        justify-content: flex-start
        padding-left: 108px
        align-items: center
        +r(1180)
            padding-left: 0px
            justify-content: center
            width: 100%
        & > div
            max-width: 456px
            display: flex
            flex-direction: column
            gap: 24px
            align-items: flex-end

.knowbase
    margin-top: 24px
    display: flex
    flex-direction: column
    gap: 24px
    +r(1180)
        margin-top: 32px
        padding-top: 64px
        & > div:first-child
            display: none
    +r(768)
        margin-top: 24px 
        padding-top: 0px

.certificates
    margin-top: 24px
    grid-column-gap: 24px
    grid-row-gap: 24px
    -webkit-column-gap: 24px
    column-gap: 24px
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    row-gap: 24px
    +r(1180)
       grid-template-columns: 1fr 1fr 
    +r(768)
       grid-template-columns: 1fr
    &__wrapper 
        margin-top: 60px
        +r(1180) 
            padding-top: 120px
            margin-top: 0px
            & > div:first-child
                display: none
        +r(768)
            padding-top: 32px
    &__panel
        margin-top: 24px
        display: flex
        flex-direction: column
        gap: 24px 
    &__buttons
        display: flex
        gap: 24px 
.certificates__header
    margin-bottom: 24px
    display: flex
    justify-content: space-between

    &_left
        display: flex
        column-gap: 8px

        & > div:not(:first-child)
            background: $surfacePrimary
.certificate
    display: flex
    flex-direction: column
    &__lines
        display: flex
        flex-direction: column
        gap: 4px
        width: calc(100% - 40px)
    &__inner
        margin-top: 12px
        display: flex
        justify-content: space-between
        flex-grow: 1
        row-gap: 12px 
    &__left
        display: flex 
        row-gap: 12px
        width: 100%
        justify-content: space-between

.faq  
    & > div > div:not(:last-child)
        margin-bottom: 20px
    &__title
        display: flex
        align-items: center
        column-gap: 8px
.faq 
    margin-top: 24px
    +r(1180)
        & .doc__header
            display: none
    
    +r(768)
        margin-top: -64px
 

    &__body
        display: grid
        grid-template-columns: 3fr 1fr
        gap: 24px
        +r(1180)
            grid-template-columns: 1fr
    &__item
        &:not(:last-child)
            margin-bottom: 24px
        &:last-child
            & .faq__item_left
                margin-bottom: 0px
            & > div:last-child
                display: none
        &_lines
            display: flex
            flex-direction: column
            gap: 8px
            margin-bottom: 24px
 
        &_left
            margin-bottom: 24px
            display: flex
            gap: 24px
            align-items: center
    &__right
        & > div
            display: flex
            flex-direction: column
            gap: 16px 
        & > div > div:first-child
            margin-bottom: 0px
        & > div > div:not(:first-child)
            display: flex
            flex-direction: column
            gap: 4px
.pathCourse
    display: grid
    grid-template-columns: 1fr 2fr
    gap: 12px
    +r(768)
        grid-template-columns: 1fr
    &__right
        display: flex
        flex-direction: column
        gap: 12px
    &__lines
        display: flex
        gap: 24px
    &__blocks
        display: flex
        flex-direction: column
        gap: 4px 

.department
    display: grid
    grid-template-columns: 2fr 1fr
    gap: 24px
    +r(1180)
        grid-template-columns: 1fr
    &__string
        display: flex
        flex-direction: column
        gap: 4px
        flex: auto

    & > div 
        display: flex
        flex-direction: column
        gap: 24px
        & > div
            display: flex
            flex-direction: column
            gap: 16px
    &__wrapper
        padding-top: 54px
        +r(1180)
            padding-top: 136px
        +r(768)
            padding-top: 54px
        & > div:first-child
            margin-bottom: 24px
            +r(1180)
                display: none
    &__head
        display: flex
        justify-content: space-between
    &__line
        display: flex
        gap: 16px
        align-items: center
    &__data
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: 32px
        align-items: center
    &__title
        display: flex
        column-gap: 8px
        align-items: center
    &__right
        +r(1180)
            order: 1
 
    &__right > div > div:not(:first-child)
        display: flex
        flex-direction: column
        gap: 4px
    &__left
        +r(1180)
            order: 2
.structure
    display: flex
    flex-direction: column
    gap: 16px
    &__lines > div
        +r(768)
            width: 76px !important
    &__titleDesktop
        +r(1180)
            display: none
    &__titleMobile
        display: none
        +r(768)
            display: flex
            column-gap: 8px
    &__wrapper
        // padding-top: 46px
        position: relative
        width: calc(100% - 312px)
        margin: 0 auto
        +r(1180)
            padding: 97px 16px 16px
            width: 100%
        +r(768)
            padding: 24px 16px 16px
        & button     
            display: flex
            justify-content: center
            align-items: center
        & ul > li
            +r(768)
                padding-top: 30px !important
        & ul li a:hover
            box-shadow: none
        & ul > li > a 
            +r(768)
                width: 160px !important

    &__data
        display: flex
        gap: 12px
        & > div:last-child
            display: flex
            flex-direction: column
            gap: 4px
.mobileTitle
    display: none
    &__circles
        display: flex
        gap: 16px
        align-items: center
    &__title
        width: 40%
        display: flex
        justify-content: center
    &__arrow
        width: 20%
    +r(1180)
        z-index: 3
        display: flex
        justify-content: space-between
        align-items: center
        position: fixed
        top: 0
        left: 0
        width: 100%
        padding: 16px 48px
        background: $surfacePrimary
        border-bottom: 1px solid $borderBrandSecondary
    +r(768)
        padding: 16px 16px