@import '../assets//styles/_variables.sass'

*
    &::-webkit-scrollbar
        width: 8px
        background: inherit

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-track-piece
        background: none

    &::-webkit-scrollbar-thumb
        background: $surfaceTertiary
        border-radius: 5px
        width: 8px
.wrapper
    background: $surfaceSecondary
    min-height: 100vh
.wrapper:has(:global(.bgWhite))
    background: $surfacePrimary
.container
    width: calc(100% - 312px)
    margin: 0 auto
    position: relative

.app
    min-height: calc(100vh - 241px) // 65px header + 152px footer + 24px margin bottom
    margin-bottom: 24px
    // margin-bottom: 60px TODO:
    display: flex
    flex-direction: column

.content
    max-width: calc(100% - 6px)

@media (min-width: 769px) and (max-width: 1180px)
    .container
        width: 100%
        padding: 0px 48px
    .app
       min-height: calc(100vh - 24px) //24px margin bottom 

@media (max-width: 768px)
    .container
        width: 100%
        padding: 0px 16px
        overflow: hidden
        padding-bottom: 24px
    .app
        min-height: calc(100vh - 24px) //24px margin bottom 
        margin-bottom: 0px
        // padding-top: 89px // header height 73px + 16px padding-top
        padding-top: 73px // header height 73px
