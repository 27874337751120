@import '../assets/styles/_variables.sass'

.mobileHeader
	display: none
	&__tabletBreadcrumbs
		display: none

	&__cancel
		width: 20px
		height: 20px

		svg 
			path 
				fill: $iconPrimary

	&__title
		display: flex
		justify-content: center
		padding: 16px 0px
		background: $surfacePrimary
		border-bottom: 1px solid $borderBrandSecondary
		margin: 0px -24px

		svg
			position: absolute
			left: 24px

		h3
			font-size: 1.6rem
			color: $textSecondary

	&__menu
		display: flex
		justify-content: space-between
		// padding: 8px 0px
		align-items: center

	&__menuLeft
		svg
			width: 6px
			height: 12px
			margin: 0px 13px

			path
				fill: $iconSecondary

		& > span
			font-size: 1.6rem
			color: $textPrimary

	&__menuRight
		display: flex
		justify-content: space-between
		width: 100%
 

		& > div
			border-radius: 10px
			display: flex
			justify-content: center
			align-items: center

	&__buttonTeacher
		margin-right: 8px
		background: $surfaceBrand
		height: 44px

		svg 
			width: 24px
			height: 24px

	&__buttonInfo
		border: 2px solid  $borderBrand
		padding: 8px 16px
		color: $textBrand
		font-size: 2rem
		cursor: pointer

		svg
			path
				fill: $iconBrand

	&__buttonInfo:hover
		border-color: $buttonBrandHovered
		color: $buttonBrandHovered

.modal
	&__header
		display: flex
		justify-content: space-between
		border-bottom: 1px solid $borderBrandSecondary
		font-size: 1.6rem
		padding: 16px
		color: $textPrimary
		line-height: 20px
		align-items: center

 

@media (min-width: 769px) and (max-width: 1180px)
 
	.mobileHeader
		display: block
		margin: 0px -48px 

		&__cancel 
			width: 20px
			height: 20px

			svg
				width: 20px
				height: 20px		

		&__tabletMenu
			display: block !important

			& > div:first-child
				display: none

		&__tabletBreadcrumbs
			display: block

			& > div 
				display: block

		&__menu	
			display: none

	.modal
		height: 100%
		top: 0px
		transform: none
		position: unset
		margin-left: auto
		width: 420px
		background: $surfacePrimary

		&__header
			font-size: 2rem
			padding: 24px

	.tabletMenu 
		& .mobileHeader__title
			display: none



@media (max-width: 768px)
	.mobileHeader
		display: block
	.mobileHeader
		&__tabletMenu 
			
	.tabletMenu .mobileHeader
		display: none
	.mobileHeader__buttonInfo
		font-size: 1.6rem
	.mobileHeader__menuRight
		display: none

	.mobileHeader__menu
		padding: 16px 0px 16px