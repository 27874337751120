@import ../assets/styles/_variables

.page__title
    line-height: 40px
    font-size: 3.2rem
    color: $textPrimary
    margin-bottom: 24px
    height: 48px
.wrapper
    padding: 24px 0
    flex: 1
    display: flex
    flex-direction: column

.table
    background-color: $surfacePrimary
    padding: 24px 0px
    box-shadow: $elevation2
    border-radius: 24px

.row
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr))
    margin-bottom: 8px
    min-height: 28px
    padding: 0px 24px
    border-bottom: 1px solid $borderBrandSecondary
    padding-bottom: 8px

    &:first-child
        padding-bottom: 20px

    &:last-child
        margin-bottom: -16px
        border-bottom: 0px
.cell
    font-size: 1.6rem
    line-height: 20px
    color: $textPrimary
    display: flex
    align-items: center
    max-width: calc( 100% - 10px) 
    position: relative

    a,span,div
        color: inherit
        text-decoration: none
 
.th
    color: $textTertiary
    &_sort
        &:hover
            cursor: pointer
.sort
    margin-right: 10px
    &_ask
        path:first-child
            fill: $iconBrand
    &_desk
        path:last-child
            fill: $iconBrand

.noItems
    flex: 1
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    color: $textSecondary
    // margin-top: 100px
    h3
        margin: 40px 0px 16px 0px
        font-weight: 700
        font-size: 32px
        line-height: 40px

    p
        font-size: 20px
        line-height: 24px


@media (min-width: 769px) and (max-width: 1180px)
    .page__title
        display: none

    // .noItems
    //     margin-top: 20px

@media (max-width: 768px)

    .noItems
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        color: $textSecondary
        margin-top: 40px

        svg 
            width: 240px
            height: 200px

        h3
            margin: 20px 0px 16px 0px
            font-weight: 700
            font-size: 24px
            line-height: 32px

        p
            font-size: 16px
            line-height: 20px
    .wrapper
        overflow: scroll
        padding-top: 0px
        margin: 0px -16px
    
    .wrapper::-webkit-scrollbar
        display: none

    .wrapper
        -ms-overflow-style: none
        scrollbar-width: none
    .table
        border-radius: 0px
        padding: 12px 0 8px
    .hideOnMobile
        display: none
    .cell
        font-size: 1.6rem
    .page__title 
        display: none
    .row
        margin-bottom: 8px
        grid-template-columns: 1fr 100px
        padding: 0 20px 8px
    .row:last-child
        margin-bottom: 0px