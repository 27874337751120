@import ../assets/styles/_variables
@import ../assets/styles/_styles
@import ../assets/styles/_mixin

.structure
    &__header
        padding-top: 24px
        margin-bottom: 24px
    &__title
        font-size: 3.2rem
        color: $textPrimary

.container
    overflow-x: auto
    padding: 24px 20px 20px 20px
    width: 100%
    margin-bottom: 24px
    min-height: calc(100vh - 186px)

.first_node:not(:has(li > ul))
    justify-content: center

.first_node > li
    margin: auto

.tree
    height: auto
    text-align: center

    &>ul>li::before
        display: none
    & ul
        padding-top: 40px
        position: relative
        transition: 0.5s
        display: flex
        text-align: center
    & li
        display: inline-table
        text-align: center
        list-style-type: none
        position: relative
        padding: 40px 24px
        transition: 0.5s
    .first_node
        // margin-top: -76px !important
        padding-top: 0

.tree li::before,
.tree li::after
    content: ""
    position: absolute
    top: 0
    right: 50%
    border-top: 1px solid $borderBrandSecondary
    width: 51%
    height: 40px

.tree li::after
    right: auto
    left: 50%
    border-left: 1px solid $borderBrandSecondary

.tree li:only-child::after,
.tree li:only-child::before
    // display: none

.tree li:only-child
    // padding-top: 40px
    padding-top: 0
    // +r(768)
    //     padding-top: 120px

.tree li:first-child::before,
.tree li:last-child::after
    border: 0 none

.tree li:last-child::before
    border-right: 1px solid $borderBrandSecondary
    border-radius: 0 12px 0 0
    -webkit-border-radius: 0 12px 0 0
    -moz-border-radius: 0 12px 0 0

.tree li:first-child::after
    border-radius: 12px 0 0 0
    -webkit-border-radius: 12px 0 0 0
    -moz-border-radius: 12px 0 0 0

.tree ul ul::before
    content: ""
    position: absolute
    top: 0
    left: 50%
    border-left: 1px solid $borderBrandSecondary
    width: 0
    height: 40px

.tree li a:hover,
.tree li a:hover i,
.tree li a:hover span,
.tree li a:hover + ul li a
    color: #000
    // border: 1px solid #94a0b4

.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before
    border-color: $borderBrandSecondary

.card
    width: 264px
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 16px
    min-height: 160px
    display: inline-flex
    align-items: center
    justify-content: center
    transition: 0.5s
    position: relative
    padding: 24px 24px 16px
    word-break: break-word
    text-decoration: none
    color: initial

.card:hover
    box-shadow: $elevation4

.eye
    border: none
    min-width: 40px
    height: 40px
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 12px
    position: absolute
    top: calc(100% + 20px)
    z-index: 1
    cursor: pointer

    &:before
        content: ""
        position: absolute
        top: -30px
        right: 71%
        border-top: 1px solid $borderBrandSecondary
        width: 51%
        height: 40px
        transform: rotate(90deg)

.tree li a:hover .eye:before
    border-color: $borderBrandSecondary

.department
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    text-align: left
    align-self: stretch
    color: $textPrimary

    &__name
        font-size: 2rem
        padding-bottom: 16px
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 16px

    &__people
        font-size: 1.6rem
        margin-top: 16px

    &__manager
        padding-bottom: 16px
        border-bottom: 1px solid $borderBrandSecondary
        display: flex
        align-items: center

.manager
    &__name
        font-size: 1.6rem
        margin-bottom: 4px

    &__position
        font-size: 1.2rem
        color: $textTertiary

.tabLine_name
    display: flex
    justify-content: left
    align-items: center

    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    color: $textPrimary

.tabLine_name_round
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    width: 40px
    height: 40px
    margin-right: 16px

    background: $surfaceTertiary
    border-radius: 26px

    font-style: normal
    font-weight: 600
    font-size: 1.6rem
    line-height: 40px
    text-align: center
    color: $textBrand

.tabLine_name_round img
    border-radius: 26px
    overflow: hidden
    width: 100%
    height: 100%
    object-fit: cover

.NoItem
    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    color: $textTertiary
    margin-bottom: 20px

.noItems
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    color: $textSecondary
    padding: 24px 0px
    min-height: calc(100vh - 186px)

    &>h3
        margin: 40px 0px 16px 0px
        font-weight: 700
        font-size: 32px
        line-height: 40px

    p
        font-size: 20px
        line-height: 24px

.input
    height: 100%
    font-size: 2rem
    line-height: 24px
    color: $textPrimary
    border: 0px
    background: $surfacePrimary
    &__search
        background-color: $surfacePrimary
        border: 2px solid $borderBrandSecondary
        border-radius: 12px
        display: flex
        align-items: center
        height: 44px
        padding: 0 17px
        svg
            margin-right: 10px
        &:focus-within
            border-color: $borderBrand
        &:hover
            border-color: $borderBrand
            cursor: pointer

.input::placeholder
    color: $textTertiary

.structure__mobileHeader
    display: none

.structureInKnowbase
    margin-top: -20px

    & .structure__header
        width: 100%
div.search_base
    +r(768)
        width: 100%
        &>button[type="button"]
            width: 100%
            gap: 16px
            padding: 0 16px
            justify-content: flex-start
            &>span
                display: inline

@media (min-width: 769px) and (max-width: 1180px)
    .structureInKnowbase .structure__mobileHeader
        display: none

    .structure
        &__header
            display: none
        &__mobileHeader
            display: block
            padding: 0 48px

@media (max-width: 768px)
    .structure
        &__header
            display: none
    .tree
        & li
            padding: 30px 8px
    .tree li::before,
    .tree li::after
        height: 30px        

    .structureInKnowbase .structure__mobileHeader
        display: none
    .input
        font-size: 1.6rem
    .knowbase
        margin: 16px -16px 16px
        &__header
            display: none
        &__search
            width: 100%
            padding: 0px 16px
            font-size: 12px
        &__input
            font-size: 1.6rem
        &__title
            display: none
        &__structure
            border-radius: 0px
            padding: 16px
            margin-bottom: 16px

            a
                font-size: 1.4rem
                line-height: 16px

            & > span
                display: none
            & > div
                width: 100%
                align-items: center

                svg
                    width: 20px
                    height: 20px
    .documents
        border-radius: 0px
        padding: 0
        overflow: scroll
        width: max-content
        min-width: 100%

        &__wrapper
            width: 100%
            overflow-x: scroll

        &__left
            width: 100%
        &__row
            font-size: 1.4rem
            line-height: 16px
            margin-bottom: 0
            padding: 8px 20px
            grid-template-columns: 1fr 1fr 1fr

            svg
                margin-right: 8px
                max-width: 24px
                width: 16px
                height: 16px
        &__head
            font-size: 1.4rem
            line-height: 20px
            margin-bottom: 12px
            padding: 20px
            margin-bottom: 0
        &__arrow
            svg
                margin-right: 12px
                width: 12px
                height: 6px
                margin-left: 6px

    .related
        &__title
            font-size: 1.6rem
            margin-bottom: 8px
        &__list
            grid-template-columns: 1fr
            gap: 12px
        &__item
            width: 100%
            border-radius: 12px
            padding: 12px
            font-size: 1.4rem

    .structure__mobileHeader
        display: block
        padding: 0px 24px
    .container
        margin-bottom: 0px
        padding: 89px 20px 20px 20px

    .card
        padding: 16px
    .department
        &__name
            font-size: 1.6rem
            line-height: 20px
            margin-bottom: 12px
            padding-bottom: 12px
        &__people
            margin-top: 12px
            font-size: 1.4rem

    .manager
        padding-bottom: 12px
        &__name
            font-size: 1.4rem
            line-height: 16px
    .tabLine_name_round
        width: 32px
        height: 32px
        font-size: 1.4rem
        margin-right: 8px

    .eye
        width: 36px
        height: 36px

        svg
            width: 20px
            height: 20px

    .noItems
        svg
            width: 260px
            height: 214px
        &>h3
            font-size: 2.4rem
            line-height: 32px
        p
            font-size: 1.6rem
            line-height: 20px
