@import ../assets/styles/_variables

.container
    display: flex
    align-items: center 

.wrapper
    background-color: $surfacePrimary
    border-bottom: 1px solid $borderBrandSecondary

.list
    display: flex
    align-items: center
    min-width: 0 // to make text-ellipsis work with flexbox

    &__item
        font-size: 1.4rem
        line-height: 18px
        color: $textTertiary 
        display: flex
        align-items: center
        &::after
            content: url('../icons/arrow-right.svg')
            display: block
            position: relative
            top: 1px 
        &:last-child
            &:after
                display: none
        a
            color: $textPrimary
            text-decoration: none
            overflow: hidden
            &:hover
                background: $buttonNeutralTertiaryHovered

@media (min-width: 769px) and (max-width: 1180px)
    .container
        padding: 8px 48px
    .wrapper
        border-bottom: none
        display: none

@media (max-width: 768px)
    .wrapper
        border-bottom: none
        display: none

    .container
        justify-content: center

    .list
        flex-direction: column

        &__item
            position: relative
            margin-right: 0px
            &:not(:last-child)
                margin-bottom: 40px

            &::after
                position: absolute
                top: auto
                bottom: -36px
                left: 50%
                margin-left: 0px
                transform: rotate(90deg) translate(0, 50%)
li.list__item.ellipsis
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: inline-block
    max-width: 100%
    a
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
.loader
    position: relative
    margin-left: 155px
    height: 37px
