@import ../../../assets/styles/_variables

ol.editor-listitem
    &__ol1
        list-style-type: number
        list-style-position: outside

    &__ol2
        list-style-type: upper-alpha
        list-style-position: outside

    &__ol3
        list-style-type: lower-alpha
        list-style-position: outside

    &__ol4
        list-style-type: upper-roman
        list-style-position: outside

    &__ol5
        list-style-type: lower-roman
        list-style-position: outside

    &__ul
        list-style-position: outside
.editor-nested-listitem
    list-style-type: none

    pre::-webkit-scrollbar
        background: transparent
        width: 10px

    pre::-webkit-scrollbar-thumb
        background: #999
.Table
    // &__dropdown
    //     z-index: 5
    //     display: block
    //     position: absolute
    //     box-shadow: rgba(0,0,0,0.2) 0 2px 8px
    //     background-color: $surfacePrimary
    //     padding: 8px 0
    //     border-radius: 16px
    //     &_item
    //         width: 100%
    //         font-size: 2rem
    //         line-height: 2.4rem
    //         font-family: $GilroyMedium
    //         padding: 8px
    //         border: none
    //         background: none
    //         &:hover
    //             cursor: pointer
    //             background-color: $secondaryBackground

    //         &_block
    //             border-bottom: 1px solid $surfaceTertiary
    //             &:last-child
    //                 border: none
    // &-cell__action-button_container
    //     position: absolute
    //     top: 0
    //     left: 0
    //     will-change: transform
button.Table-cell__action-button
    background: none
    display: flex
    justify-content: center
    align-items: center
    border: 0
    position: relative
    border-radius: 50%
    padding: 0
    margin: 0
    align-items: center
    width: 21px
    height: 21px
    svg
        width: 14px
        height: 14px
        margin-right: 0
    &:hover
        cursor: pointer
.PlaygroundEditorTheme__table
    border-collapse: collapse
    border-spacing: 0
    overflow-y: scroll
    table-layout: fixed
    width: 100%

    &Selected
        outline: 2px solid $textPrimary

    &Cell
        border: 1px solid $surfaceTertiary
        min-width: 75px
        vertical-align: top
        text-align: start
        padding: 6px 8px
        position: relative
        cursor: default
        outline: none

    &CellSortedIndicator
        display: block
        opacity: 0.5
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 4px
        // background-color: $secondaryGray

    &CellResizer
        position: absolute
        right: -4px
        height: 100%
        width: 8px
        cursor: ew-resize
        z-index: 10
        top: 0

    &CellHeader
        text-align: start

    &CellEditing
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4)
        border-radius: 3px

@keyframes table-controls
    0%
        opacity: 0

    100%
        opacity: 1

.PlaygroundEditorTheme__tableCellResizeRuler
    display: block
    position: absolute
    width: 1px
    background-color: rgb(60, 132, 244)
    height: 100%
    top: 0

.PlaygroundEditorTheme__tableCellActionButtonContainer
    display: block
    right: 5px
    top: 6px
    position: absolute
    z-index: 4
    width: 20px
    height: 20px

.PlaygroundEditorTheme__tableCellActionButton
    background-color: #eee
    display: block
    border: 0
    border-radius: 20px
    width: 20px
    height: 20px
    color: #222
    cursor: pointer

    &:hover
        background-color: #ddd

.TableCellResizer__resizer
    position: absolute

.TableNode__contentEditable
    min-height: 20px
    border: 0px
    resize: none
    cursor: text
    display: block
    position: relative
    tab-size: 1
    outline: 0px
    padding: 0
    user-select: text
    font-size: 15px
    white-space: pre-wrap
    word-break: break-word
    z-index: 3
.table-cell-action-button-container
    position: absolute
    top: 0
    left: 0
    will-change: transform

.table-cell-action-button
    background-color: green
    display: flex
    justify-content: center
    align-items: center
    border: 0
    position: relative
    border-radius: 15px
    color: #222
    display: inline-block
    background-color: rgb(228, 228, 228)
    cursor: pointer
iframe.Youtube_Node_Embeded
    width: 100%
    max-width: 100%
    aspect-ratio: 16 / 9
// div.typeahead-popover
//     font-family: $Gilroy
//     font-size: 1.4rem
//     line-height: 16px
//     ul
//         box-shadow: 0px 4px 16px $surfaceTertiary
//         // border: 1px solid $surfaceTertiary
//         padding: 0
//         border-radius: 8px
//         &>li
//             color: $secondaryDarkGray
//             padding: 12px 20px
//             &:hover
//                 cursor: pointer
//                 background-color: $secondaryBackground
.editor__text
    &_subscript
        font-size: 0.6em
        vertical-align: sub !important
    &_superscript
        font-size: 0.6em
        vertical-align: super

.PlaygroundEditorTheme__layoutContainer
    display: grid
    gap: 10px
    margin: 10px 0

.PlaygroundEditorTheme__layoutItem
    border: 1px dashed #ddd
    padding: 8px 16px
.editor-quote
    margin: 12px 0
    border-left-color: $surfaceTertiary
    border-left-width: 8px
    border-left-style: solid
    padding-left: 16px
    font-style: italic
.editor-text-strikethrough
    text-decoration: line-through
.editor-text-code
    background-color: $surfaceTertiary
    padding: 2px 0.8rem
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif
    font-size: 90%
    line-height: 1.8
    cursor: default
    white-space: pre-wrap
    word-break: break-word
    position: relative
    -webkit-user-select: none
    user-select: none
    box-decoration-break: slice

span.editor-image
    cursor: default
    display: inline-block
    position: relative
    -webkit-user-select: none
    user-select: none
    // width: 100%
    &>div
        width: 100%
