@import '../../assets/styles/_variables.sass'

.tab
    cursor: pointer

    &_wrap 
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        padding: 12px 12px 0px

.tab_wrap_text
    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    display: flex
    align-items: center
    text-decoration: none

.secondaryDarkGray 
    color: $textSecondary

.primaryBase 
    color: $textBrand

.borderActive
    background: $surfaceBrand
    width: 100%
    height: 2px
    border-radius: 4px

.borderOff
    width: 100%
    height: 2px
    border-radius: 4px

.activeTab 
    padding-bottom: 10px
    position: relative

    &:after
        content: ""
        display: block
        background: $surfaceBrand
        width: calc(100% + 24px)
        position: absolute
        height: 2px
        border-radius: 4px
        bottom: -1px
        left: -12px

.activeTabOff
    padding-bottom: 10px

    &:hover 
        color: $textBrand


@media (max-width: 768px)
    .tab
        &_wrap
            padding: 8px 12px 0px

            &_text
                white-space: nowrap
                font-size: 1.6rem
    .activeTab
        padding-bottom: 8px